<template>
  <div style="width: 80%; margin-left: 200px; margin-right: 200px; padding-top: 40px;">
    <v-row  justify="center" style="width:90%; height: 100%;">
           
              <v-card class="form-card" flat>
                <v-row no-gutters>
                  <v-col cols="10" md="6" >
                    <v-img
                      src="../../assets/images/books.jpeg"
                      class="form-card__image">
                    </v-img>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-card-text justifier="center">
                      
                      <v-form>
        <v-container>
          <!-- Center the image -->
          <v-row class="d-flex justify-center mb-4">
            <v-col cols="12" class="text-center">
              <v-img
                contain
                max-width="180"
                src="@/assets/images/logo.png"
                class="responsive-img mx-auto"
              ></v-img>
            </v-col>
          </v-row>
           
          <!-- Form Fields and Buttons -->
          
            <v-col cols="12" md="12">
              <v-text-field
                v-model="lastname"
                label="Nom"
                outlined
              
                class="mx-auto"
              ></v-text-field>

              <v-text-field
                v-model="firstname"
                label="Prénom"
                outlined
              
                class="mx-auto"
              ></v-text-field>

              <v-text-field
                v-model="email"
                label="E-mail"
                outlined
              
                class="mx-auto"
              ></v-text-field>

              <v-text-field
                v-model="numTel"
                label="Numéro de téléphone"
                outlined
              
                type="tel"
                hint="Entrez votre numéro de téléphone"
                class="mx-auto"
              ></v-text-field>

              <v-text-field
                v-model="newPassword"
                label="Mot de passe"
                outlined
              
                type="password"
                hint="Entrez un mot de passe"
                class="mx-auto"
              ></v-text-field>

              <v-text-field
                v-model="cPassword"
                label="Confirmer le mot de passe"
                outlined
              
                type="password"
                hint="Confirmez le mot de passe"
                class="mx-auto"
              ></v-text-field>
            </v-col>
          

          <!-- Center the buttons -->
          <v-row class="d-flex justify-center">
            <v-col cols="12" class="text-center">
              <v-btn
                @click="verifyPasswordAndEmail"
                color="primary"
                class="me-3 mt-3"
                style="background-color: #A8FE39; color:#ffffff; width: 150px;"
              >
                Sign Up
              </v-btn>
              <v-btn
                @click="resetForm"
                color="secondary"
                outlined
                class="mt-3"
                style="width: 150px;"
              >
                Cancel
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
        </v-card-text>
      </v-col>
    </v-row>
  </v-card>

</v-row>
    <v-row justify="center">
      <v-dialog v-model="dialogVisible" max-width="600">
        <v-card>
          <v-card-text class="d-flex flex-column justify-center align-center">
            <v-avatar v-if="!valid" color="error" class="mt-10" icon size="50">
              <v-icon size="2rem" color="white">
                {{ icons.mdiAlertCircleOutline }}
              </v-icon>
            </v-avatar>
            <v-avatar v-if="valid" color="success" class="mt-10" icon size="50">
              <v-icon size="2rem" color="white">
                {{ icons.mdiAccountCheck }}
              </v-icon>
            </v-avatar>
          </v-card-text>
          <v-card-text>
            <h3>{{ passwordError }}</h3>
          </v-card-text>
          <v-card-text>
            <v-btn
              v-if="!valid"
              @click="closeDialog"
              color="primary"
              class="mt-4"
              style="background-color: #A8FE39; color:#0B0B0B"
            >
              fermer
            </v-btn>
            <v-btn
              v-if="valid"
              @click="updateUser"
              color="primary"
              class="mt-4"
              style="background-color: #A8FE39; color:#0B0B0B"
            >
              Confirmer
            </v-btn>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import {
  mdiAlertCircleOutline,
  mdiAccountCheck,
} from "@mdi/js";
import { ref, watch, onMounted } from "@vue/composition-api";
import firebase from "firebase/compat/app";
import "firebase/auth";
import Constant from "@/utils/constants";
import axios from 'axios';

export default {
  setup() {
    const users = ref([]);
    const filteredUsers = ref([]);
    const headers = ref([
      { text: "First Name", value: "firstName" },
      { text: "Last Name", value: "lastName" },
      { text: "Email", value: "email" },
      { text: "Phone Number", value: "numTel" },
    ]);
    const loading = ref(false);
    const search = ref('');
    const totalUsers = ref(0);
    const itemsPerPage = ref(10);
    const currentPage = ref(1);
    const user = ref({
      firstname: "",
      lastname: "",
      email: "",
      role: "LIBRARY",
      numTel: "",
    });
    const valid = ref(false);
    const email = ref("");
    const lastname = ref("");
    const firstname = ref("");
    const numTel = ref("");
    const newPassword = ref("");
    const cPassword = ref("");
    const passwordError = ref("");
    const dialogVisible = ref(false);
    const icons = {
      mdiAlertCircleOutline,
      mdiAccountCheck,
    };

    async function checkAuth() {
      const user = firebase.auth();
      if (!user) {
        throw new Error("User is not logged in.");
      }
      return user;
    }

    async function fetchUsers(page) {
      if (isNaN(page) || page <= 0) {
        console.error("Invalid page number:", page);
        return;
      }
      loading.value = true; // Start loading
      try {
        const response = await axios.get(
          `${Constant.QORANI}user/getByRole/${user.value.role}/${page - 1}`
        );
        users.value = response.data.userList;
        filteredUsers.value = users.value;
        totalUsers.value = response.data.totalNbrElement;
      } catch (error) {
        console.error("Error fetching users:", error);
      } finally {
        loading.value = false; // End loading
      }
    }

    async function updateUser() {
      try {
        await checkAuth(); // Ensure user is authenticated

        // Create the user account using Firebase Authentication
        await firebase.auth().createUserWithEmailAndPassword(
          email.value,
          newPassword.value
        );

        // Prepare user data from the form
        const userData = {
          firstname: firstname.value,
          lastname: lastname.value,
          email: email.value,
          numTel: numTel.value,
          role: "LIBRARY",
          createdBy: firebase.auth().email,
        };

        // Send data to the backend
        const response = await fetch(Constant.QORANI + "user/register", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(userData),
        });

        if (!response.ok) {
          throw new Error("Network response was not ok.");
        }

        passwordError.value = "";
        dialogVisible.value = false;
        alert("Account created successfully!");
        window.location.reload();
      } catch (error) {
        passwordError.value = error.message;
        showDialog();
      }
    }

    function verifyPasswordAndEmail() {
      const emailFormatRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Email format regular expression
      const minLength = 8;
      const uppercaseRegex = /[A-Z]/;
      const specialCharsRegex = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/\-="']/;
      const digitRegex = /\d/;

      if (!email.value) {
        passwordError.value = "E-mail est requis.";
        valid.value = false;
        showDialog();
      } else if (!emailFormatRegex.test(email.value)) {
        passwordError.value = "Format e-mail invalide.";
        valid.value = false;
        showDialog();
      } else if (!newPassword.value) {
        passwordError.value = "Mot de passe requis.";
        valid.value = false;
        showDialog();
      } else if (newPassword.value.length < minLength) {
        passwordError.value = "Le mot de passe doit comporter au moins 8 caractères.";
        valid.value = false;
        showDialog();
      } else if (!uppercaseRegex.test(newPassword.value)) {
        passwordError.value = "Le mot de passe doit contenir au moins une lettre majuscule.";
        valid.value = false;
        showDialog();
      } else if (
        !(
          specialCharsRegex.test(newPassword.value) ||
          digitRegex.test(newPassword.value)
        )
      ) {
        passwordError.value = "Le mot de passe doit contenir au moins un chiffre, un caractère spécial ou un espace.";
        valid.value = false;
        showDialog();
      } else if (newPassword.value !== cPassword.value) {
        passwordError.value = "Les mots de passe ne correspondent pas.";
        valid.value = false;
        showDialog();
      } else {
        passwordError.value = "Le mot de passe et email sont valides !";
        valid.value = true;
        showDialog();
      }
    }

    function showDialog() {
      dialogVisible.value = true;
    }

    function closeDialog() {
      dialogVisible.value = false;
    }

    function resetForm() {
      lastname.value = "";
      firstname.value = "";
      email.value = "";
      numTel.value = "";
      newPassword.value = "";
      cPassword.value = "";
      passwordError.value = "";
      valid.value = false;
      dialogVisible.value = false;
    }

    // Fetch users when the component is mounted
    onMounted(() => {
      fetchUsers(currentPage.value);
    });

    // Watch for changes in currentPage
    watch(currentPage, (newPage) => {
      fetchUsers(newPage);
    });

    return {
      users,
      filteredUsers,
      headers,
      loading,
      search,
      totalUsers,
      itemsPerPage,
      currentPage,
      user,
      valid,
      email,
      lastname,
      firstname,
      numTel,
      newPassword,
      cPassword,
      passwordError,
      dialogVisible,
      icons,
      fetchUsers,
      updateUser,
      verifyPasswordAndEmail,
      showDialog,
      closeDialog,
      resetForm,
    };
  },
};
</script>


<style lang="scss" scoped>
.user-form-section {
  background-color: #ffffff;
  border-radius: 12px;
  
}

.v-form {
  margin-top: 40px;
}

.v-btn {
  font-weight: 600;
  border-radius: 24px;
  text-transform: none;
  font-size: 0.875rem; // 14px
  letter-spacing: 0.5px;
  min-width: 120px;
  height: 40px;
  line-height: 40px;
  padding: 0 16px;
}

.v-btn.primary {
  background-color: #4CAF50;
  color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.v-btn.secondary {
  border-color: #4CAF50;
  color: #4CAF50;
  box-shadow: none;
}

.v-btn:hover {
  filter: brightness(90%);
}

.v-divider {
  margin: 24px 0;
  border-top: 1px solid #e0e0e0;
}

.v-dialog .v-card {
  border-radius: 16px;
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
}

.v-card-text h3 {
  color: #333;
  font-size: 1.125rem; // 18px
  font-weight: 500;
  text-align: center;
}

.v-text-field .v-input__control {
  border-radius: 12px;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  background-color: #fafafa;
}

.responsive-img {
  width: 100%;
  max-width: 200px;
  margin-left: 12px;
}
.responsive {
  width: 100%;
  max-width: 200px;
  margin-left: 12px;
}
.form-card {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  background-size: cover;
  background-position: center;
  border-radius: 8px;
}

.form-card__image {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  height: 100%;
  width: 100%;
  margin-right: 30px;
  
 
}
</style>